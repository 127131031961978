<template>
    <main>
      <div
        class="modal fade"
        id="modalAsignacionAreasDetalle"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Asignacion de areas
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="card-waves ">
                <div class="card-header bg-light text-dark"><i class="fa fa-book fa-fw text-primary"></i>Datos</div>
                <div class="card-body">
                  <div class="row">
                    
                    <div class="form-group col-lg-6 col-md-4 col-sm-12">
                        <label class="form-label">Profesional</label>
                        <v-select-user v-model="detalle.id_profesional"></v-select-user> 
                    </div>

                    <div class="form-group col-lg-6 col-md-4 col-sm-12">
                        <label class="form-label">Área de Laboratorio</label>
                        <v-select-area-lab v-model="detalle.id_area_lab"></v-select-area-lab>
                    </div>
   
                  
                    
                  </div>
                </div>
              </div>
              
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="save()"
              >
                Guardar<i class="fa fa-save fa-fw"></i>
              </button>
              <button
                type="button"
                class="btn btn-light btn-sm"
                @click="cerrar()"
              >
                Cerrar<i class="fa fa-times-circle fa-fw"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </template>
  <script>
  

  import asignacionAreasDetalleService from '../../../../../../../services/asignacionAreasDetalleService';
  import vSelectUser from "../../../../../../../components/common/vSelectUser";

  import vSelectAreaLab from "../../../../../../../components/common/vSelectAreaLab";

  import Toast from "../../../../../../../components/common/utilities/toast";
  import { required } from "vuelidate/lib/validators";
  import $ from "jquery";
  
  export default {
    props : ['id'],
    components: {
        vSelectUser,vSelectAreaLab
    },
    data() {
      return {
        detalle: {
          id_profesional: "",
          id_area_lab: "",
          id_asignacion_area:"",
        },
        profesional:"",
        type: "store",
      };
    },
    validations() {
      return {
        detalle: {
            id_profesional: {required},
            id_area_lab: {required},
            id_asignacion_area:{required},
  
        }
        
      };
    },
    methods: {
      
      async save() {
        try {
          this.$v.detalle.$touch();
           
          if (this.$v.detalle.$invalid) {
            return;
          }

          this.detalle.id_asignacion_area = parseInt(this.id);//this.id;

          this.LoaderSpinnerShow();

          if (this.type === "store") {
            await asignacionAreasDetalleService.store(this.detalle);
            this.type = "store";
            this.limpiarCampos();
          } 
  
          this.LoaderSpinnerHide();
  
          Toast.fire({
            icon: "success",
            title: "Datos guardados con exito",
          });

          this.$emit("cargar-asignacion-areas", this.detalle.id_asignacion_area);
         // this.$emit("cargar-examen", this.examen.id_area);
  
          $("#modalAsignacionAreasDetalle").modal("hide");
          
        } catch (err) {
          console.error(err);
          this.LoaderSpinnerHide();
          Toast.fire({
            icon: "error",
            title: "Ocurrio un error al procesar la solicitud",
          });
        }
      },
      async limpiarCampos() {
      this.detalle.id_profesional = "";
      this.detalle.id_area_lab = "";
    
 
    },
    cerrar() {
        this.limpiarCampos();
        $("#modalAsignacionAreasDetalle").modal("hide");
    },  
    async modalGuardar(id) {
        this.$nextTick(async () => {
          this.type = "store";
          $("#modalAsignacionAreasDetalle").modal("show");
          this.detalle.id_asignacion_area=id;

          //this.detalle.id_asignacion_area=this.$route.params?.id_area;
          
          //this.detalle.id_examen=this.$route.params?.id_examen || null;

         // this.examen.id_area=id;
          //this.examen.id_examen=this.$route.params?.id_examen || null; 
          
      });
    }
    }
  };
  </script>
  